.welcome-block-title {
    font-size: 42px;
    letter-spacing: 0;
    line-height: 52px;
    margin: 5px 0 0;
}

.welcome-block-login {
    color: #464646;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    float: right;
    margin: 0;
}

.welcome-block-contractNo {
    color: #0091DA;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    float: right;
    margin: 15px 0 0;
}

.welcome-block-account {
    color: #464646;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: right;
    float: right;
    clear: right;
    margin: 0;
    width: 100%;
}
@media (min-width: 758px) and (max-width: 996px) {
    .dashboard-welcome-container {
        margin-top: 45px;
    }
}
@media (min-width: 216px) and (max-device-width: 758px) {
    .welcome-block-title {
        color: #464646;
        font-size: 24px;
        letter-spacing: 0;
        line-height: 30px;
        margin: 30px 0 0;
        overflow-wrap: break-word !important;
    }
    .welcome-block-login {
        height: 18px;
        width: 288px;
        color: #464646;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 18px;
        margin: 0;
        float: left;
    }
    .welcome-block-contractNo {
        height: 17px;
        width: 289px;
        color: #0091DA;
        font-family: "FS Elliot Pro";
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
        margin: 5px 0 0;
        float: left;
    }
    .welcome-block-account {
        color: #464646;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 18px;
        float: left;
        text-align: left;
    }
}