
.dashboard-acc-balance-card{
    height: 440px;
    width: 100%;
    margin-top: 30px;
}

.dashboard-acc-fund-dist-card{
    height: 100%;
    width: 100%;
    margin-top: 16px;
}

.dashboard-acc-funds-table-card{
    height: 100%;
    width: 100%;
    margin-top: 16px;
}

.dasboard-shortcut-cards-section{
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-shortcut-card {
    height: 100%;
    width: 50%;
    border-radius: 10px;
    margin-top: 16px;
}

.dashboard-promo-banner{
    margin-top:40px;
}

.dashboard-welcome-container{
    margin-top:5px;
}

.dashboard-remarks-section{
    margin-top: 47px;
}

.dashboard-remarks-section-line1{
    display: inline-block;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    margin-top:15px;
    width: 100%;
}

.dashboard-remarks-section-line2,.dashboard-remarks-section-line3{
    display: inline-block;
    color: #767676;
    font-family: "FS Elliot Pro" !important;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top:12px;
}

@media (min-width:768px) {

    .dashboard-acc-balance-card{
        height: 450px;
        width: 100%;
        margin-top: 60px;
    }
    
    .dashboard-acc-fund-dist-card{
        height: 450px;
        width: 100%;
        margin-top: 60px;
    }
    
    .dashboard-acc-funds-table-card{
        height: 100%;
        width: 100%;
        margin-top: 40px;
    }
    
    .dashboard-shortcut-card{
        height: 130px;
        margin-top: 40px;
    }
    
    .dashboard-promo-banner{
        margin-top:25px;
    }

    .dashboard-welcome-container{
        margin-top:5px
    }

    .dashboard-remarks-section{
        margin-top: 60px;
    }
    
    .dashboard-remarks-section-line1{
        display: inline-block;
        color: #464646;
        font-family: "FS Elliot Pro" !important;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 26px;
        margin-top:0px;
        width: 100%;
    }
    
    .dashboard-remarks-section-line2,.dashboard-remarks-section-line3{
        display: inline-block;
        color: #767676;
        font-family: "FS Elliot Pro" !important;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 18px;
        margin-top:15px;
    }
}
.db-popup-modal-blurbg {
    display: block !important;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 50px;
    width: 100%;
    height: 100%;
    background-color: rgba(234,246,247,0.5) !important;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    -webkit-animation: none !important;
    animation: none !important;
}
.db-popup-modal .db-popup-modal-dialog {
    text-align: center;
    margin: 5% auto;
    width: 520px
}


.db-popup-text {
    color: #464646;
    font-size: 16.5px;
    text-align: justify;
}

.db-popup-ok-btn {
    height: 60px;
    width: 145px !important;
    margin: 40px 0 0;
    background: linear-gradient(206.57deg, #00C4D9 0%, #0091DA 100%);
    color: #FFFFFF !important;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
    border-radius: 35px;
    display: block;
    text-decoration: none;
    border: none;
    outline: none;
    cursor: pointer;
}
.db-popup-ok-btn:hover {
    color: #0091DA !important;
    background: linear-gradient(207deg, #EAF6F7 0%, #E4EFF9 100%);
}
