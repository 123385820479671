.acc-balance-section {
    height: 134px;
}

.acc-stats-section {
    height: 250px;
    background-image: linear-gradient(206.57deg, rgba(234, 246, 247, 0.35), rgba(228, 239, 249, 0.35));
}

.acc-linkto-section {
    cursor: pointer;
    height: 55px;
}

.line1 {
    box-sizing: border-box;
    height: 1px;
    width: 100%;
    border: 1px solid rgba(0, 145, 218, 0.2);
}

.dbab-m20 {
    display: inline-block;
  
}

.dbab-m20-new {
    display: inline-block;
    
}

.dbab-m15 {
    display: inline-block;
    margin-top: 15px
}

.acc-balance-section-title {
    height: 26px;
    width: 142px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
}

.acc-balance-as-at-date {
    height: 22px;
    width: 258px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
}

.acc-balance-equals {
    height: 26px;
    width: 12px;
    color: #464646;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    transform: translate(-50%, -20%);
}

.acc-balance-amount {
    height: 42px;
    width: 159px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 32px;
    letter-spacing: -0.5px;
    line-height: 42px;
}

.acc-balance-currency-text {
    height: 22px;
    width: 35px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
}

.acc-balance-dropdown-title {
    height: 22px;
    width: 108px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
}

.acc-balance-net-contri-text {
    height: 18px;
    width: 218px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
}

.acc-balance-stats-amount {
    height: 36px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 28px;
    letter-spacing: 0;
    line-height: 36px;
}

.acc-balance-return-gainloss-text {
    height: 22px;
    width: 121px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
}

.acc-balance-lossgain-percentage {
    height: 26px;
    width: 75px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 26px;
    margin-left: 5px;
}

.acc-balance-view-portfolio-text {
    height: 22px;
    color: #0091DA;
    font-family: "FS Elliot Pro" !important;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    margin-top: auto;
    margin-bottom: auto;
}

.acc-balance-view-portfolio-image {
    height: 18px;
    width: 18px;
    margin-left: 15px;
    margin-top: auto;
    margin-bottom: auto;
}

.acc-balance-d-center {
    display: flex;
    align-items: center;
}

.acc-balance-d-center-new {
    align-items: center;
}

.acc-balance-dropdown-icon-expand {
    height: 10px;
    width: 10px;
    margin-left: 5px;
}

.nav-link-dropdown-section {
    padding: 0px;
}

.dropdown-menu-area {
    padding: 0 0 15px 0;
}

.acc-balance-decimal {
    height: 26px;
    width: 28px;
    color: #464646;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 26px;
}

.gain-loss-badge {
	color: #FFFF;
    background-color: #00A29B;
	display: inline-block;
    padding: 1px 10px;
    font-family: "FS Elliot Pro";
    font-size: 13px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 15px;
    margin-left: 5px;
}

.loss-badge {
    background-color: #C00000 !important;
}

.gain-amt {
    color: #00A29B !important;
}

.loss-amt {
    color: #C00000 !important;
}

@media (min-width: 768px) {
    .acc-balance-section {
        height: 150px;
    }
    .acc-stats-section {
        height: 240px;
        background-image: linear-gradient(206.57deg, rgba(234, 246, 247, 0.35), rgba(228, 239, 249, 0.35));
    }
    .acc-linkto-section {
        height: 64px;
    }
    .line1 {
        box-sizing: border-box;
        height: 1px;
        width: 100%;
        border: 1px solid rgba(0, 145, 218, 0.2);
    }
    .dbab-m20 {
        display: inline-block;
    }
    .dbab-m20-new {
        display: inline-block;
        /* margin-top: 20px */
    }
    .dbab-m15 {
        display: inline-block;
        margin-top: 15px
    }
    .acc-balance-section-title {
        height: 26px;
        width: 290px;
        color: #464646;
        font-family: "FS Elliot Pro" !important;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 26px;
    }
    .acc-balance-as-at-date {
        height: 22px;
        width: 290px;
        color: #464646;
        font-family: "FS Elliot Pro" !important;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 22px;
    }
    .acc-balance-amount {
        height: 52px;
        width: 215px;
        color: #464646;
        font-family: "FS Elliot Pro" !important;
        font-size: 42px;
        letter-spacing: 0;
        line-height: 52px;
        overflow-wrap: anywhere;
    }
    .acc-balance-currency-text {
        height: 22px;
        width: 35px;
        color: #464646;
        font-family: "FS Elliot Pro" !important;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
    }
    .acc-balance-dropdown-title {
        height: 22px;
        width: 108px;
        color: #464646;
        font-family: "FS Elliot Pro" !important;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
    }
    .acc-balance-net-contri-text {
        height: 22px;
        width: 251px;
        color: #464646;
        font-family: "FS Elliot Pro" !important;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
    }
    .acc-balance-stats-amount {
        height: 36px;
        color: #464646;
        font-family: "FS Elliot Pro" !important;
        font-size: 28px;
        letter-spacing: 0;
        line-height: 36px;
    }
    .acc-balance-return-gainloss-text {
        height: 22px;
        width: 121px;
        color: #464646;
        font-family: "FS Elliot Pro" !important;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
    }
    .acc-balance-lossgain-percentage {
        height: 26px;
        width: 75px;
        color: #464646;
        font-family: "FS Elliot Pro" !important;
        font-size: 20px;
        letter-spacing: 0;
        line-height: 26px;
        margin-left: 5px;
        ;
    }
    .acc-balance-view-portfolio-text {
        height: 26px;
        color: #0091DA;
        font-family: "FS Elliot Pro" !important;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 26px;
        margin-bottom: 20px;
    }
    .acc-balance-view-portfolio-image {
        height: 20px;
        width: 20px;
        margin-left: 5px;
        margin-bottom: 20px;
    }
    .acc-balance-d-center {
        display: flex;
        align-items: center;
    }
    .acc-balance-d-center-new {
        /* display: flex; */
        align-items: center;
    }
    .acc-balance-dropdown-icon-expand {
        height: 10px;
        width: 10px;
        margin-left: 5px;
    }
}


.durationStyle{
    width: 100%;
}