.sll-container {
    padding: 5px 20px;
    margin: 2px 50px;
}

.sll-title {
    font-size: 42px;
    color: #464646;
    margin: 20px 0 0;
}

.sll-login-ip {
    height: 60px !important;
    color: #464646 !important;
    border-radius: 35px !important;
    border: 1px solid rgba(70, 78, 126, .15) !important;
    font-style: normal !important;
    line-height: 22px !important;
    padding: 0 30px 0 17px !important;
    display: block;
}

.sll-login-ip:focus {
    box-shadow: 0 0 0 .2rem rgb(0 123 255/25%) !important;
    border-color: #80bdff !important;
}

.sll-login-ip:focus-visible {
    outline: 1px solid rgba(70, 78, 126, .15) !important;
}

.sll-note {
    font-size: 18px;
    width: 100% !important;
    display: block;
    margin: 25px 0px 25px 0px;
    letter-spacing: 0;
    text-align: center;
    padding: 10px 25px;
    color: #464646 !important;
}

.sll-login-btn {
    background: linear-gradient(207deg, #00c4d9 0%, #0091da 100%);
    color: #ffffff;
    font-size: 18px;
    border-radius: 34px;
    width: 100% !important;
    height: 50px;
    display: block;
    text-decoration: none;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 25px 0px 25px 0px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    padding: 10px 25px;
}

.sll-login-btn:disabled {
    background: #c7c7c7 !important;
}

.sll-login-btn:focus {
    outline: none !important;
}

.sll-login-btn:hover {
    background: #d6ebf8 !important;
    color: #00c4d9;
    width: 100% !important;
}

@media screen and (max-width:767px) {
    .sll-container {
        padding: 5px 0;
        margin: 3px 10px;
    }
}