.downloads {
  height: 52px;
  width: 215px;
  color: #464646;
  font-family: "FS Elliot Pro";
  font-size: 42px;
  letter-spacing: 0;
  line-height: 52px;
}

.product-brochure {
  height: 26px;
  width: 146px;
  color: #464646;
  font-family: "FS Elliot Pro";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
}

.explanatory-memorand {
  height: 26px;
  width: 411px;
  color: #464646;
  font-family: "FS Elliot Pro";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
}

.product-key-facts {
  height: 26px;
  width: 599px;
  color: #464646;
  font-family: "FS Elliot Pro";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
}

.fund-fact-sheets-p {
  height: 26px;
  width: 694px;
  color: #464646;
  font-family: "FS Elliot Pro";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
}

.this-site-makes-exte {
  height: 18px;
  width: 1130px;
  color: #767676;
  font-family: "FS Elliot Pro";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 80px;
  margin-top: 20px;
}

.field-seprator {
  height: 1px;
  background-color: #e1e6f4;
}

.downloads-mg-bottom {
  margin-top: 59px;
}

@media (max-width: 780px) {
  .downloads {
    height: 30px;
    width: 325px;
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 24px;
    letter-spacing: 0;
    line-height: 30px;
  }
}

@media screen and (max-width:767px) {

  .downloads-mg-bottom {
    margin-top: 40px;
  }

}