.db-fund-distribution-fund-dot-name{
  display:flex;
  align-items: flex-start;
  margin-bottom:9px;
}

.db-pie{
  margin-top: -20px;
  height: max-content;
}

.db-fund-distribution-fund-dot{
  margin-top:3px
}

.db-fund-distribution-fund-name {
  display: inline-block;
  height: 100%;
  width: 234px;
  color: #464646;
  font-family: "FS Elliot Pro" !important;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 22px;
}


.expandBlock{
  margin-top:15px;
  margin-bottom: 15px;
}

.expandIcon{
  height: 18px;
  width: 18px;
  margin-left: 8px;
}

#expandTogle{
  height: 22px;
  width: 100%;
  color: #0091DA;
  font-family: "FS Elliot Pro" !important;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  }

.db-fund-distribution-title {
  display: inline-block;
  height: 26px;
  color: #464646;
  font-family: "FS Elliot Pro" !important;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
}

.db-boss{
  position: relative;
}

.db-fund-distribution-funds-list-section{
  margin-top:40px;
}

.db-fund-distribution-fund-names-section{
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.db-fund-distribution-collapsed-card{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 17px;
  padding-bottom: 17px;
}

.db-fund-distribution-expanded-card{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 17px;
  padding-bottom: 17px;
}

.db-fund-distribution-collapsed-card-title{
  height: 26px;
  width: 149px;
  color: #464646;
  font-family: "FS Elliot Pro" !important;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
}

.db-fund-distribution-collapsed-card-icon{
  height: 17px;
  width: 14px;
}

.db-fund-distribution-transperant-overlay{
    position: absolute;
    top:0;
    height:0px;
    width:100%;
    z-index: 90;
    background-image: linear-gradient(180deg, #FFFFFF 0px, rgba(255,255,255,0) 60px);
}



@media (min-width:768px){

  .db-fund-distribution-fund-dot{
    margin-top:3px
  }

  .db-fund-distribution-fund-dot-name{
    display:flex;
    align-items: flex-start;
  }

  .db-fund-distribution-fund-name {
    display: inline-block;
    height: 100%;
    width: 290px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
  }

  .expandBlock{
    margin-top:10px;
  }

  .expandIcon{
    height: 14px;
    width: 17px;
    margin-left: 8px;
  }

  .db-fund-distribution-funds-list-section{
    margin-top:5px;
  }

  #expandTogle{
    height: 26px;
    width: 100%;
    color: #0091DA;
    font-family: "FS Elliot Pro" !important;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
  }

  .db-fund-distribution-title {
    display: inline-block;
    height: 26px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    margin-left:25px;
  }

  .db-fund-distribution-fund-names-section{
    height: 276px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;

  }


.scrollContent:before {
  content: '';
  display: block;
  position: fixed;
  top: 20%;
  width: 100%;
  height: 100px;
  background-image: linear-gradient(to top, rgba(255,255,255,0), #fff);
  pointer-events: none;
}

  .db-fund-distribution-fund-names-section-expanded{
    height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
  }

  .db-fund-distribution-fund-names-section-collapsed{
    height: 100px;
    width: 100%;
  }

  .db-fund-distribution-transperant-overlay,.db-fund-distribution-transperant-overlay-bottom{
    position: absolute;
    top:0;
    height:60px;
    width: 85%;
    z-index: 90;
    background: linear-gradient(180deg,#FFFFFF  0px,    rgba(255,255,255,0) 60px);
  }

  .db-fund-distribution-transperant-overlay-bottom{
    top:240px;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0px,  #FFFFFF 60px);
  }

}

.db-fund-distribution-fund-names-section-expanded::-webkit-scrollbar {
    width: 10px;
    border-radius: 3px;
}

.db-fund-distribution-fund-names-section-expanded::-webkit-scrollbar-thumb {
    height: 118px;
    width: 6px;
    opacity: 0.5;
    border-radius: 3px;
    background-color: #b1b2b5;
}

.db-fund-distribution-fund-names-section-expanded::-webkit-scrollbar-track {
    border-radius: 10px;
}

.noMoneyImage {
    height: 105px;
    width: 191px;
}

.noAsset {
    height: 22px;
    color: #C7C7C7;
    font-family: "FS Elliot Pro";
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center !important;
}

.noMoneyImg {
    margin-right: auto;
    margin-left: auto;
    display: inline;
    margin-top: 30px;
}