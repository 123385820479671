.fac-content{
    background: #FFFFFF !important;
    padding: 0px 0px 60px 0px;
}

.fac-title {
  color: #464646;
  font-family: "FS Elliot Pro";
  font-size: 42px;
  letter-spacing: 0;
  line-height: 52px;
  margin: 40px 0px 60px 0px;
}

.fac-risk-indicator {
  height: 14px;
  width: 14px;
  border-radius: 2px;
}

.risk-level {
  color: #464646;
  font-family: "FS Elliot Pro";
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 10px;
}

.risk-select{
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    margin-top: 0px;
    margin-bottom: 40px;
    cursor: pointer;
}

.risk-select-arrow {
  background-image: url("../assets/Icon_Arrow_Down.svg") !important;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 96%;
}

.fac-note{
    color: #767676;
    font-family: "FS Elliot Pro";
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
}


.fac-table td,
.fac-table th,
.fac-table thead th {
    border-bottom: 1px solid rgba(70,78,126,0.15);
    border-top: none;
}

.fac-table thead th {
    vertical-align: top;
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 13px ;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    background-color: #F1F7FC;
}

.fac-table tbody th,
.fac-table tbody td {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    padding-top: 19px;
    padding-bottom:19px;
}

.fac-table tbody th{
    font-weight: bold;
    position: sticky;
    z-index: 1;
    left: 0;
    background-color: #FFFFFF;
    width: 340px;
    padding-left: 0px;
}

.fixed-position-col{
    position: sticky;
    z-index: 1;
    left: 0;
}
.fac-table-subcol{
    font-size: 11px !important;
    line-height: 15px !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.fac-tb-title-align{
    text-align: center;
    border-bottom: none !important;
    background-color: #E4EFF9 !important;
    padding-top:4px !important;
    padding-bottom: 3px !important;
}

.fac-hr{
    border: 1px solid rgba(70,78,126,0.15);
    margin-top: 59px;
    margin-bottom: 30px;
}

.fac-note-title{
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    margin-bottom: 15px;
}

.fac-notes-para > p{
    color: #767676;
    font-family: "FS Elliot Pro";
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
}

.risk-spectrum-title{
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
}

.fac-table td, .fac-table th {
    padding-left: 0px;
    padding-right: 10px;
}
#fnd-name{
    padding: 12px 15px;
}
#std-mngmt-fee,
#effective-fee{
    padding-top: 12px;
    padding-bottom: 12px;
}

.wd-33{
    width: 33%;
}

.wd-13{
    width: 13%;
}

.wd-54{
    width: 54%;
}

.wd-9{
    width: 9%;
}

.wd-60{
    width: 60%;
}

.wd-20{
    width: 20%;
}

.wd-80{
    width: 80%;
}
@media screen and (max-width:767px){
    .fac-title {
        font-size: 24px;
        line-height: 30px;
        margin: 85px 0 40px 0;
    }

    .risk-level{
        margin-bottom: 8px;
    }

    .risk-select{
        font-size: 15px;
        margin-bottom: 30px;
    }

    .risk-select-arrow{
        background-position: 93%;
    }

    .fixed-position-col{
        width: 200px;
    }

    .risk-spectrum-title{
        font-size: 13px;
        line-height: 18px;
    }

    .fac-hr{
        margin-top: 40px;
        margin-bottom: 15px;
    }

    .fac-table tbody th,
    .fac-table tbody td{
        font-size: 13px;
        line-height: 18px;
        padding-top: 14px;
        padding-bottom: 14px;
    }

    .fac-table tbody th{
        padding-left: 16px;
    }
    #fnd-name{
        padding: 13px 16px;
    }

    #std-mngmt-fee,
    #effective-fee{
        padding-top: 13px;
        padding-bottom: 13px;
    }

    .fac-table tbody th{
        max-width: 200px;
        min-width: 200px;
        word-wrap: break-word;
    }

    .fac-risk-indicator {
        height: 12px;
        width: 12px;
        margin-top: 2px;
    }
}