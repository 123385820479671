.fso-title {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 42px;
    letter-spacing: 0;
    line-height: 52px;
    margin-bottom: 60px;
}

.fso-sub-title {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 34px;
}

.fso-card-active{
    border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 5px 0 rgba(70,78,126,0.03), 0 5px 30px 0 rgba(70,78,126,0.1);
  cursor: pointer !important;
}
.fso-card {
    box-shadow: none;
    border: none;
    border-radius: 10px;
    background: linear-gradient(201.88deg, rgba(234, 246, 247, 0.6) 0%, rgba(228, 239, 249, 0.6) 100%);
}

.fso-cardbody {
    margin-right: 15px;
    margin-left: 15px;
    flex: 100%;
}

.fso-contributionType {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    margin-bottom: 20px;
    margin-left: 25px;
    margin-top: 20px;
}

.fso-edit {
    color: #0091DA !important;
    font-family: FS Elliot Pro !important;
    font-size: 18px;
    letter-spacing: 0 ;
    line-height: 26px;
    text-align: right;
    margin-bottom: 20px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 20px;
    cursor: pointer !important;
}
.fso-edit:hover{
    text-decoration: underline !important;
}

.fso-edit-switch-out {
    margin-top: 0px;
    margin-left: 24px;
}

.fso-line1 {
    box-sizing: border-box;
    height: 1px;
    border: 1px solid rgba(70, 78, 126, 0.15);
}

.fso-fund-name {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    margin-top: 20px;
}

.fso-fund-name-sub-title {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
}

.fso-left-fn-sub-title {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    padding-left: 25px;
}

.fso-input {
    box-sizing: border-box !important;
    height: 60px !important;
    width: 340px !important;
    border: 1px solid rgba(70, 78, 126, 0.15) !important;
    border-radius: 35px !important;
    background-color: #FFFFFF !important;
    margin-bottom: 15px !important;
    outline: none !important;
}

.fso-lbl-switch-out {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
}

@media (min-width: 0px) and (max-device-width: 768px) {
    .fso-lbl-switch-out {
        margin-top: 13px;
        margin-bottom: 22px;
    }
}

@media (min-width: 768px) {
    .fso-input {
        margin-left: 15px;
    }
}

@media (min-width:1199px) and (max-device-width: 2255px) {
    .fso-edit {
        margin-left: 130px;
    }
    .fso-edit-switch-out {
        margin-left: 156px;
    }
}
@media (min-width:992px) and (max-device-width: 1199px) {
    .fso-edit {
        margin-left: 102px;
    }
    .fso-edit-switch-out {
        margin-left: 128px;
    }
}
@media (min-width:768px) and (max-device-width: 992px) {
    .fso-edit {
        margin-left: 61px;
    }
    .fso-edit-switch-out {
        margin-left: 86px;
    }
}
@media (min-width: 510px) and (max-device-width: 767px) {
    .fso-edit {
        margin-left: 20px;
    }
    .fso-edit-switch-out {
        margin-left: 43px;
    }
}
@media (min-width: 0px) and (max-device-width: 361px) {
    .fso-edit {
        margin-left: -20px;
    }
    .fso-edit-switch-out {
        margin-left: 8px;
    }
}
.fso-risk-return-spectrum {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
}

.fso-line-mrgtop {
    margin-top: 60px;
}

.fso-switch-amount-div {
 background: linear-gradient(201.88deg, rgba(234, 246, 247, 0.6) 0%, rgba(228, 239, 249, 0.6) 100%);
}

.tenth_separator {
    border: 1px solid white;
    z-index: 100;
}

.progress {
    margin-top: 10px;
    width: 100%;
    position: relative;
    margin-left: 15px;
    margin-right: 15px;
    background-size: cover !important;
}

.under_part {
    background: linear-gradient(90deg, #8EE6B6 0%, #F7DA97 27.57%, #FF774A 100%);
    z-index: 0;
    height: 30px;
}

.progressBarhigh {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    text-align: right;
    margin: 6px 0px;
    width: 100%;
}

.progressBarLow {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    margin: 6px 0px;
    width: 100%;
}

.fsobreadcrumbs {
    color: #0091DA;
    font-family: FS Elliot Pro !important;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
}

.breadcrumbMargin {
    margin-left: 4px;
}

.hkidamountcss {
    color: #0091DA;
    font-family: FS Elliot Pro !important;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
}

.input-div-padding {
    margin-bottom: 30px;
}

@media (min-width: 0px) and (max-device-width: 768px) {
    .fso-sub-title {
        font-size: 18px;
        line-height: 26px;
    }
}
@media (min-width: 0px) and (max-device-width: 768px) {
    .fso-contributionType {
        font-size: 16px;
        line-height: 22px;
    }
}
@media (min-width: 0px) and (max-device-width: 768px) {
    .fso-edit {
        font-size: 16px;
        line-height: 22px;
    }
}
@media (min-width: 0px) and (max-device-width: 768px) {
    .fso-fund-name {
        font-size: 15px;
        line-height: 22px;
    }
}
@media (min-width: 0px) and (max-device-width: 768px) {
    .fso-left-fn-sub-title {
        font-size: 13px;
        line-height: 18px;
    }
}
.fsoinputplaceholder{
  color: #464646 !important;
  font-family: FS Elliot Pro !important;
  font-size: 16px !important;
  letter-spacing: 0 !important;
  line-height: 22px !important;
  height: 60px !important;
  }
  @media (min-width: 0px) and (max-device-width: 768px) {
    .fsoinputplaceholder {
        font-size: 15px !important;
        height: 40px !important;
    }
}
.fso-estimatedamountlbl{
  color: #464646 !important;
  font-family: FS Elliot Pro !important;
  font-size: 16px !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  line-height: 22px !important;
}
.fso-contributiomargin{
margin-top: 60px !important;
}
@media (min-width: 0px) and (max-device-width: 768px) {
    .hkidamountcss {
        font-size: 16px !important;
        line-height: 22px;
    }
    .fso-contributiomargin{
        margin-top: 40px !important;
    }
}
@media (min-width: 768px)  {
    .input-div-padding {
        padding-left: 30px !important;
    }
}
.fso-common-pds-error{
  margin-top: -15px !important;
  color: #e74e1c !important;
  font-family: FS Elliot Pro !important;
  font-size: 13px !important;
  letter-spacing: 0 !important;
  line-height: 18px !important;
  margin-bottom: 15px !important;
}
.fso-fund-name-padding{
    padding-left: 0px !important;
}
.fso-holding-balance{
  color: #464646 !important;
  font-family: FS Elliot Pro !important;
  font-size: 15px !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  line-height: 22px !important;
  text-align: right !important;
}
.lTGFNote{
  padding-top: 10px;
  color: #464646;
  font-family: "FS Elliot Pro";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
}
@media(max-width: 767px) {
  .lTGFNote {
  padding-top: 8px !important;
  color: #464646;
  font-family: "FS Elliot Pro";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
   text-align: left;
}
}
.accBalFund-link {
  color: #0091da;
  letter-spacing: 0;
}
.accBalFund-link:hover {
  color: #0091da;
  text-decoration: none;
}
.fso-note{
    color: #767676;
    font-family: "FS Elliot Pro";
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 20px;
}