.bottom-border-line {
  border-bottom: 1px solid rgba(0, 145, 218, 0.25);
}

.downloadLink {
  text-decoration: none !important;
}

@media (min-width: 316px) and (max-device-width: 376px) {
  .sheetImg {
    height: 25px !important;
    width: 25px !important;
  }
}

