  .image-wrapper{
    display: flex;
    justify-content: flex-end;
    padding-right: 50px;
    margin-top:40px;
  }

  .oval_image{
    height: 80px;
  }

.imp_notes_section_points{
  color: #464646;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 22px;
}

.imp_notes_section_points_container{
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 22px;
}

.imp_notes_section_points_container br{
  line-height: 150%;
}

.imp_notes_container{
  display: flex;
  justify-content: space-between;
}
  
.impnotes_section,.impnotes_section_maximised{
  background-color: white;
  margin-top: 40px;
  border-radius: 10px;
}

.impnotes_section{
  align-items: center;
}

.impnotes_section_maximised{
  height: 100%;
  padding-bottom: 22px;
}

.impnotes_section_ip_text{
  color: #464646;
  font-family: "FS Elliot Pro" !important;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 34px;
  width: 80%;
}

.imp_notes_container_maximized > .fund_details_read_btn_container{
  visibility: hidden;
}


.fund_details_read_btn_container{
  border-radius: 34px;
  background: linear-gradient(206.57deg, #00C4D9 0%, #0091DA 100%);
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.fund_details_read_btn_text{
  color: #FFFFFF;
  font-family: "FS Elliot Pro" !important;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  margin-top: 15px;
}

.fund_details_read_btn {
  border-radius: 34px !important;
  display: block;
  text-decoration: none;
  cursor: pointer !important;
  background: linear-gradient(207deg, #00c4d9 0%, #0091da 100%);
  padding: 10px 0px;
  border: none;
  outline: none !important;
  width: 20% ;
  font-family: "FS Elliot Pro";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  color: #FFFFFF;
}

.fund_details_read_btn:hover{
    color: #0091DA;
    background: linear-gradient(207deg, #EAF6F7 0%, #E4EFF9 100%);
}

.fund_details_usd {
  box-sizing: border-box;
  border: 1px solid rgba(70,78,126,0.15);
  border-radius: 20px;
  color: #464646;
  font-family: "FS Elliot Pro";
  padding: 1.5px 10.5px;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
}

  .fund_details_title {
    height: 100%;
    width: 100%;
    background: linear-gradient(201.88deg, #EAF6F7 0%, #E4EFF9 100%);
  }

  .fund_details_title_fund_name {
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 55px;
    letter-spacing: -0.5px;
    line-height: 65px;
    padding-top: 49px;
  }

  .fund_details_imp_notes_section{
    background-color: white;
    border-radius: 10px;
    height: 100px;
    width: 1050px;
    margin-top: 200px;
  }

  .fund_details_title_fund_units{
    height: 65px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 55px;
    letter-spacing: -0.5px;
    line-height: 65px;
    margin-top: 45px;
  }


  .fund_details_title_bubble{
    height: 36px !important;
    width: 72px !important;
    border-radius: 20px;
    background-color: #FFFFFF;
  }

  @media (max-width: 768px) {

    .image-wrapper{
      display: flex;
      justify-content: center;
      margin-top:40px
    }


    .oval_image{
      height: 50px;
    }

    .fund_details_read_btn_text{
      color: #FFFFFF;
      font-family: "FS Elliot Pro" !important;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px;
      text-align: center;
      margin-top: 10px;
    }

    .fund_details_read_btn,
    .fund_details_read_btn:hover{
        font-size: 16px;
        line-height: 22px;
        width: 30%;
    }

    .imp_notes_container{
      display: flex;
      justify-content: space-around;
      align-items: center;
    }


    .impnotes_section, .impnotes_section_maximised{
      background-color: white;
      margin-top: 40px;
      border-radius: 10px;
      align-items: center;
    }

    .impnotes_section_maximised{
      height: 100%;
      padding-bottom: 22px;
    }

    .impnotes_section_ip_text{
      color: #464646;
      font-family: "FS Elliot Pro" !important;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 26px;
      display: inline-block;
      width: 70%;
    }

    .fund_details_read_btn_container{
      border-radius: 34px;
      background: linear-gradient(206.57deg, #00C4D9 0%, #0091DA 100%);
      text-align: center;
      margin-top: 15px;
      display: flex;
      justify-content: center;
    }

    .imp_notes_section_points{
      color: #464646;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 22px;
    }


    .fund_details_title {
      height: 100%;
      width: 100%;
      background: linear-gradient(201.88deg, #EAF6F7 0%, #E4EFF9 100%);
    }
  
    .fund_details_title_fund_name {
      height: 100%;
      width: 100%;
      color: #464646;
      font-family: "FS Elliot Pro" !important;
      font-size: 32px;
      letter-spacing: -0.5px;
      line-height: 42px;
      padding-top: 49px;
      margin-top:40px;
    }
  
    .fund_details_imp_notes_section{
      background-color: white;
      border-radius: 10px;
      height: 70px;
      width: 325px;
      margin-top: 100px;
    }
  
    .fund_details_title_fund_units{
      height: 65px;
      color: #464646;
      font-family: "FS Elliot Pro" !important;
      font-size: 55px;
      letter-spacing: -0.5px;
      line-height: 65px;
      margin-top: 45px;
    }

    .imp_notes_container_maximized > .fund_details_read_btn_container{
      visibility: hidden;
    }
  
  
    .fund_details_title_bubble{
      height: 36px !important;
      width: 72px !important;
      border-radius: 20px;
      background-color: #FFFFFF;
    }

    .fund_details_usd {
      font-size: 16px;
      line-height: 22px;
    }
  }

@media (min-width:767px) and (max-width:991px) {
  .fund_details_title_fund_name {
    margin-top: -11px;
  }
}

.imp_note_hr {
  border-top: 1px solid rgba(0,145,218,0.2);
}