.mpf-emp-card {
  background: linear-gradient(198deg, #e1f1fc 100%, #deeffd 9%);
}

.simp-smart-card {
  background: linear-gradient(199deg, #d2f1fd, #bbe6fc);
}

.mpf-other-card {
  background: linear-gradient(198deg, #e4edf8 100%, #e2e6f3 9%);
}

.orso-card {
  background: linear-gradient(198deg, #e0f4f8 100%, #def5fa 9%);
}

.login-type-cards-title:hover {
  color: #0091da !important;
  text-decoration: none !important;
}

.login-navbar {
  position: relative;
}

.login-type-title {
  width: 100%;
  color: #464646;
  font-size: 42px;
  letter-spacing: 0;
  line-height: 52px;
  margin: 5px 0 88px;
}

.login-card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  border-radius: 15px;
  border: solid 1px #e8e8e8;
  background-color: #fff;
  box-shadow: none !important;
  backface-visibility: hidden;
  width: 100%;
  padding: 24px 14px;
  margin: 0;
}

.empPlan {
  height: 90px;
  width: 90px;
  margin: 0 auto 16px;
}

.login-type-cards-title {
  color: #464646 !important;
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 26px;
  font-weight: bold;
  text-decoration: none;
  padding: 0 !important;
  margin: 0 0 0 !important;
}

.lt-subtext {
  flex-grow: 0;
  margin: 16px 0 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #464646;
}

.lt-title-transparent {
  color: transparent !important;
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 26px;
  font-weight: bold;
  text-decoration: none;
  padding: 0 !important;
  margin: 0 0 0 !important;
}

.emplogin-pwdToggle {
  cursor: pointer;
  float: right;
  margin: -41px 10px 0 0;
}

.login-page2-title {
  width: 100%;
  color: #464646;
  font-size: 42px;
  letter-spacing: 0;
  line-height: 52px;
  margin: 10px 0 40px;
}

.emp-login-card {
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(70, 78, 126, 0.03), 0 5px 30px 0 rgba(70, 78, 126, 0.08);
  margin: 0 0 30px !important;
}

.pds-tab .pds-tab-panel {
  background-color: transparent;
  color: #464646;
  border: none;
}

.el-tab .el-tab-panel {
  padding: 20px 30px !important;
}

.el-tab .el-tab-list button.el-tab-button {
  margin: 0 0 0 0 !important;
  padding: 15px;
}

.pds-tab .pds-tab-list button.pds-tab-button[aria-selected="true"] {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
}

.pds-tab .pds-tab-list button.pds-tab-button[aria-selected="false"] {
  font-size: 18px;
  font-weight: normal !important;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
}

.placeholder-login {
  height: 60px !important;
  color: #464646 !important;
  border-radius: 35px !important;
  border: 1px solid rgba(70, 78, 126, 0.15) !important;
  font-style: normal !important;
  line-height: 22px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.placeholder-login:focus {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%) !important;
  border-color: #80bdff !important;
}

::placeholder {
  /* Firefox */
  color: #464646 !important;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px !important;
  opacity: 0.5;
  font-style: normal !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #464646 !important;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  opacity: 0.5;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #464646 !important;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  opacity: 0.5;
}

.login-btn {
  height: 60px !important;
  font-size: 18px;
  line-height: 26px;
  border-radius: 35px;
  display: block;
  text-decoration: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  width: 100%;
  color: #ffffff;
}

.login-btn:hover {
  background: #d6ebf8 !important;
  color: #00c4d9;
  height: 60px !important;
  font-size: 18px;
  line-height: 26px;
  border-radius: 35px;
  display: block;
  text-decoration: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  width: 100%;
}

.login-btn:disabled {
  background: #c7c7c7 !important;
  color: #ffffff;
  height: 60px !important;
  font-size: 18px;
  line-height: 26px;
  border-radius: 35px;
  display: block;
  text-decoration: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  width: 100%;
}

.frgt-pin-link {
  background-color: transparent !important;
  display: block;
  text-decoration: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  color: #0091da !important;
  font-size: 16px;
  line-height: 22px;
  margin-top: 20px !important;
}

.frgt-pin-link:hover {
  background-color: transparent !important;
  display: block;
  text-decoration: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  color: #0091da !important;
  font-size: 16px;
  line-height: 22px;
  margin-top: 20px !important;
}

.empnote-title {
  color: #464646;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  margin: 0 0 22px;
}

.emphave-que {
  width: 100%;
  color: #464646;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 15px;
}

.empnote-text {
  color: #767676;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: 0px;
}

.empnote-text-que {
  color: #767676;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  margin: 0 0 1px;
  width: 100%;
}

.cust-service-link {
  color: #0091da;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
}

.cust-service-link:hover {
  color: #0091da;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  text-decoration: none;
}

.internet-time {
  line-height: 26px;
  height: 20px;
  width: 20px;
  margin: 50px 0 5px;
}

.empnote-title-2 {
  display: block !important;
  height: 22px;
  width: 100%;
  color: #464646;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 1px;
}

.empnote-text-time {
  height: 22px;
  width: 100%;
  color: #0091da;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 20px !important;
}

.break-line-internet {
  box-sizing: border-box;
  height: 1px;
  border: 1px solid rgba(70, 78, 126, 0.15);
  margin: 50px 0 15px;
}

.emplogin-input {
  margin: 0 0 20px;
}

.emp-login {
  padding: 30px 0 60px !important;
}

@media screen and (max-width: 992px) {
  .login-card {
    padding: 32px 15px 55px;
    margin: 10px 0;
  }

  .empPlan {
    height: 60px;
    width: 60px;
    margin: 0 auto !important;
  }

  .login-type-cards-title {
    font-size: 16px;
    line-height: 22px;
    margin: 6px 0 0 0 !important;
  }

  .emplogin-pwdToggle {
    margin: -37px 10px 0 0;
  }

  .emp-login-card {
    border-radius: 0 !important;
    background-color: transparent;
    box-shadow: none !important;
    border: none !important;
    padding: 0 !important;
    margin: 17px 0 !important;
  }

  .login-page2-title {
    font-size: 24px;
    line-height: 30px;
    margin: 0 !important;
  }

  .el-tab .el-tab-panel {
    padding: 15px 0 !important;
  }

  .login-type-title {
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 34px;
  }

  .pds-tab .pds-tab-list button.pds-tab-button[aria-selected="true"] {
    font-size: 17px;
    line-height: 22px;
  }

  .pds-tab .pds-tab-list button.pds-tab-button[aria-selected="false"] {
    font-size: 17px;
    line-height: 22px;
  }

  .placeholder-login {
    height: 50px !important;
    font-size: 15px !important;
  }

  ::placeholder {
    /* Firefox */
    font-size: 15px !important;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 15px !important;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    font-size: 15px !important;
  }

  .login-btn {
    height: 50px !important;
    font-size: 16px;
    line-height: 22px;
  }

  .login-btn:disabled {
    height: 50px !important;
    font-size: 16px;
    line-height: 22px;
  }

  .login-btn:hover {
    height: 50px !important;
    font-size: 16px;
    line-height: 22px;
  }
}