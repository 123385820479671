.toastsuccesscontainer {
    padding: 20px 16px;
    background: #00A29B;
    width: 100%;
    border: none;
    border-radius: 10px;
  }
  .toasterrorcontainer {
    padding: 20px 16px;
    background-color: #E74E1C;
    width: 100%;
    border: none;
    border-radius: 10px;
  }

  .toastsessionexpirecontainer {
    padding: 20px 16px;
    background-color: #FF9231;
    width: 100%;
    border: none;
    border-radius: 10px;
  }

  .toastflex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    font-family: Open Sans;
  }

  .toastmessage {
    padding: 0px 16px;
    margin: 0px;
    color: #FFFFFF;
    font-family: FS Elliot Pro !important;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
  }
  .toast_message__3jL-W{
    color: #FFFFFF;
    font-family: FS Elliot Pro !important;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
  }
