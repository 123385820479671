.db-shortcut-d-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding-bottom: 20px; 
  padding-top: 20px;
}

.db-shortcut-title {
    display: inline-block;
    height: 18px;
    width: 107px;
    color: #464646;
    font-family: "FS Elliot Pro"  !important;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    margin-top: 12px;
}

.db-shortcut-icon {
  height: 24px;
  width: 24px;
}

.db-shortcut-icon-link{
  height: 26px;
  width: 26px;
}


@media (min-width: 768px) {
  .db-shortcut-d-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .db-shortcut-title {
    height: 26px;
    width: 195px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 12px;
  }

  .db-shortcut-icon {
    margin-top: 30px;
    height: 30px;
    width: 30px;
  }

  .db-shortcut-icon-link{
    margin-top: 30px;
    height: 30px;
    width: 30px;
  }
}
