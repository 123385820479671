.gl-icon{
    margin-left: 4px;
    cursor: pointer;
}

.gnl-modal-background {
    background-color: rgba(51,51,51,0.9);
}

@media (min-width: 576px) {
    .gnl-modal-dlg {
        max-width: 720px
    }
}

.gnl-modal-body {
    padding: 56px 32px 46px;
    color: #464646;
    font-family: "FS Elliot Pro";
}

.gnl-modal-title {
    font-size: 24px;
    letter-spacing: 0;
    line-height: 34px;
    font-weight: normal;
    margin-bottom: 12px;
}

.gnl-bold {
    font-weight: bold !important;
}

.gnl-modal-info{
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
    font-weight: normal;
}

.gnl-btn-mg {
    margin-top: 24px;
}

.gnl-btn {
    border-radius: 34px;
    border: none;
    background: linear-gradient(206.57deg, #00C4D9 0%, #0091DA 100%);
    color: #FFFFFF;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
    padding: 17px 0;
    width: 100%;
}

.gnl-btn:hover {
    color: #0091DA;
    background: linear-gradient(207deg, #EAF6F7 0%, #E4EFF9 100%);
}

.gnl-modal-content {
    border-radius: 15px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 5px 0 rgba(70,78,126,0.03), 0 5px 30px 0 rgba(70,78,126,0.1);
}

@media screen and (max-width:767px){
    .gnl-modal-body {
        padding: 32px 16px;
    }
    .gnl-btn-mg {
        padding-left: 16px;
        padding-right: 16px;
    }
}