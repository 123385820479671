.verifyLabel {
    color: #464646;
    font-weight: normal;
    font-family: "FS Elliot Pro" !important;
    height: 52px;
    font-size: 42px;
    letter-spacing: 0;
    line-height: 52px;
}

.addr-wrd {
    word-break: break-word;
}

.enter-pin-to-confirm {
    height: 26px;
    width: 279px;
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
}

.pinErrorAddress {
    height: 18px;
    color: #e74e1c;
    font-family: "FS Elliot Pro";
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
}

.hong-kong-sar {
    height: 60px !important;
    width: 330px !important;
    opacity: 0.5;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
}

.rectangle-copy-3 {
    box-sizing: border-box;
    height: 60px;
    width: 370px;
    border: 1px solid rgba(70, 78, 126, 0.15);
    border-radius: 35px !important;
    background-color: #FFFFFF;
}

.line-2 {
    box-sizing: border-box;
    height: 1px;
    border: 1px solid #CFCFCF;
    margin-left: -100px;
    margin-right: -100px;
}

.button-verify {
    height: 26px;
    width: 63px;
    color: #FFFFFF;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
}

.info-title-verify {
    font-size: 20px;
    color: #464646;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 34px;
}

.button-verify-update {
    height: 26px;
    width: 63px;
    color: #FFFFFF;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
}

.color-verify-update {
    height: 60px;
    width: 340px;
    border-radius: 34px;
    background: linear-gradient(206.57deg, #00C4D9 0%, #0091DA 100%);
}

.rectangle-copy-16 {
    border-radius: 15px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 5px 0 rgba(70, 78, 126, 0.03), 0 5px 30px 0 rgba(70, 78, 126, 0.08);
}

@media only screen and (max-device-width: 480px) {
    .color-verify-update {
        height: 50px;
        width: 208px;
        border-radius: 34px;
        background: linear-gradient(206.57deg, #00C4D9 0%, #0091DA 100%);
    }
    .rectangle-copy-16 {
        border-radius: 15px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 5px 0 rgba(70, 78, 126, 0.03), 0 5px 30px 0 rgba(70, 78, 126, 0.08);
    }
    .hong-kong-sar {
        height: 50px !important;
        width: 208px !important;
        opacity: 0.5;
        color: #464646;
        font-family: "FS Elliot Pro" !important;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 22px;
    }
    .line-2 {
        box-sizing: border-box;
        height: 1px;
        border: 1px solid #CFCFCF;
        margin-left: -20px;
        margin-right: -20px;
    }
}

.select-arrow {
    background-image: url("../assets/Icon_Arrow_Down.svg");
    background-repeat: no-repeat;
    background-size: auto;
}

@media only screen and (max-width: 768px) {
    .enter-pin-to-confirm {
        height: 26px;
        width: 279px;
        color: #464646;
        font-family: "FS Elliot Pro";
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 26px;
    }
    .info-title-verify {
        font-size: 16px;
        color: #464646;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 34px;
    }
    .edit-link {
        font-size: 16px;
        color: #0091da;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
        text-decoration: none;
    }
}