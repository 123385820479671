.modal-card {
    border: none !important;
    border-radius: 15px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 5px 0 rgba(70, 78, 126, 0.03), 0 5px 30px 0 rgba(70, 78, 126, 0.08);
    width: 90% !important;
}

.modal-title {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
}

.modal-text {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
}

.modal-button {
    height: 50px;
    width: 100%;
    color: #FFFFFF;
    font-family: "FS Elliot Pro";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    border-radius: 34px;
    background: linear-gradient(206.57deg, #00C4D9 0%, #0091DA 100%);
    border: none !important;
}

.modal-button:focus {
    outline: none !important;
}

.modal-img {
    display: block;
    margin: 0 auto;
}

.modal-bg-colour {
    background-color: rgba(234, 246, 247, 0.5) !important;
}