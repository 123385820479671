.fpap-content {
    background: #FFFFFF !important;
    padding: 0px 0px 60px 0px;
}

.fpap-title {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 42px;
    letter-spacing: 0;
    line-height: 52px;
    margin: 40px 0px;
}

.fpap-desc {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
    margin-bottom: 40px;
}

.fpap-label {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    margin-bottom: 6px;
}

.fpap-select {
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    margin-top: 0px;
    margin-bottom: 30px;
    cursor: pointer;
}

.fpap-select-arrow {
    background-image: url("../assets/Icon_Arrow_Down.svg") !important;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 91.5%;
}

.fpap-select-scheme-arrow {
    background-image: url("../assets/Icon_Arrow_Down.svg") !important;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 95%;
}

.fpap-dropdown-menu {
    border-radius: 10px;
    background-color: #FFFFFF !important;
    background-image: none !important;
    box-shadow: 0 20px 40px 2px rgba(70, 78, 126, 0.16);
    padding-top: 14px;
    padding-bottom: 13px;
    width: 92%;
    margin-left: 4px;
    z-index: 1;
}

.fpap-dropdown-menu li {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 25px;
    padding: 14px 21.91px;
    cursor: pointer;
}

.fpap-dropdown-menu li:hover {
    color: #0091DA;
}

.fpap-filter-btn {
    border-radius: 34px !important;
    display: block;
    text-decoration: none;
    cursor: pointer !important;
    padding: 14px 53px;
    border: none;
    outline: none !important;
    height: 50px;
    width: 100%;
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    background: transparent;
    border: 1.5px solid rgba(0, 145, 218, 0.2);
    ;
}

.fpap-plus-icon {
    background-image: url("../assets/Arrow_right.svg") !important;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 93.8%;
}

.fpap-funds-found {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
}

.fpap-hr-1 {
    border: 1px solid #E4EFF9;
    margin-top: 0px;
    margin-bottom: 30px;
}

.fpap-hr-2 {
    border-top: 1px solid rgba(0, 145, 218, 0.2);
    margin-top: 15px;
    margin-bottom: 15px;
}

.fpap-hr-3 {
    border-top: 1px solid rgba(0, 145, 218, 0.2);
    margin-top: 80px;
    margin-bottom: 30px;
}

.fpap-sub-title {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
}

.fpap-title-row {
    margin-bottom: 15px;
    padding-left: 22px;
    padding-right: 22px;
}

.fpap-info {
    padding: 18px 22px 15px 22px;
}

.fpap-fund-name {
    color: #0091DA;
    font-family: "FS Elliot Pro";
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    margin-bottom: 12px;
    cursor: pointer;
}

.fpap-fund-name:hover {
    color: #0071b3;
}

.fpap-mg-top {
    margin-top: 0px;
}

.fpap-fund-pt {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 42px;
    letter-spacing: 0;
    line-height: 52px;
}

.fpap-hkd {
    box-sizing: border-box;
    height: 27px;
    width: 60px;
    border: 1px solid rgba(70, 78, 126, 0.15);
    border-radius: 20px;
    color: #464646;
    font-family: "FS Elliot Pro";
    padding: 0.5px 10.5px;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
}

.fpap-risk-name {
    font-family: "FS Elliot Pro";
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
}

.fpap-low,
.fpap-low sup {
    color: #00A29B;
}

.fpap-moderate {
    color: #FFC02E;
}

.fpap-moderate-to-high {
    color: #FF9231;
}

.fpap-high,
.fpap-high sup {
    color: #E74E1C;
}

.fpap-smp-low,
.fpap-smp-low sup {
    color: #94d8fa;
}

.fpap-smp-low-to-medium {
    color: #00a29b;
}

.fpap-smp-medium {
    color: #f9c707;
}

.fpap-smp-medium-to-high {
    color: #ff9330;
}

.fpap-smp-high,
.fpap-smp-high sup {
    color: #e54e1c;
}

.fpap-spectrum {
    margin-top: 10px;
    margin-bottom: 10px;
}

.fpap-sub-pts {
    color: #767676;
    font-family: "FS Elliot Pro";
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
}

.fpap-bold {
    font-weight: bold;
}

.fpap-pc {
    font-family: "FS Elliot Pro";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    margin-bottom: 3px;
    text-align: center;
}

.fpap-pc-na,
.fpap-pc-na sup {
    color: #767676;
}

.fpap-yearly {
    margin-top: 4px;
    margin-bottom: 5px;
    text-align: center;
}

.brdr-right {
    border-right: 1px solid rgba(70, 78, 126, 0.15);
}

.fpap-dt-mg-top {
    margin-top: 13px;
}

.fpap-risk-ttl {
    padding-right: 10px;
}

.fpap-bg-blue {
    background-color: #E4EFF9;
}

.fpap-bg-wht {
    background-color: #FFFFFF;
}

.fpap-note,
.fpap-note p {
    color: #767676;
    font-family: "FS Elliot Pro";
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
}

.fpap-rectangle-spectrum {
    height: 40px;
    width: 401px;
}

.fpap-rectangle-spectrum-text {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    margin-top: 16px;
    text-align: right;
    width: 401px;
}

.fpap-spectrum-mg {
    margin-top: 79px;
    margin-bottom: 57px;
}

.fpap-last-note-mgb {
    margin-bottom: 20px;
}

.fpap-scheme-name {
    margin-top: -9px;
}

.fpap-record-not-found {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
}

.fpap-new-label {
    border-radius: 11.5px;
    background: linear-gradient(206.57deg, #00C4D9 0%, #0091DA 100%);
    color: #FFFFFF;
    font-family: "FS Elliot Pro";
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    padding: 1px 9px;
    margin-left: 10px;
}

.scheme-pdt {
    padding-top: 18px;
}

.fpap-card {
    border-radius: 10px;
    background: linear-gradient(206.57deg, rgba(234, 246, 247, 0.6) 0%, rgba(228, 239, 249, 0.6) 100%);
    box-shadow: none !important;
    border: none !important;
}

.fpap-card-body {
    padding: 25px 30px 16px;
}

.fpap-card-subttl {
    color: rgba(70, 70, 70, 1);
    font-family: "FS Elliot Pro";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
}

.fpap-card-title {
    color: rgba(70, 70, 70, 1);
    font-family: "FS Elliot Pro";
    font-size: 42px;
    letter-spacing: 0;
    line-height: 52px;
}

.fpap-card-note {
    color: rgba(70, 70, 70, 1);
    font-family: "FS Elliot Pro";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 22px;
    margin-top: 30px;
}

.fpap-learn-btn {
    border-radius: 34px;
    border: none;
    background: linear-gradient(206.57deg, #00C4D9 0%, #0091DA 100%);
    color: #FFFFFF;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
    padding: 12px 40px;
    width: 100%;
}

.fpap-learn-btn:hover {
    color: #0091DA;
    background: linear-gradient(207deg, #EAF6F7 0%, #E4EFF9 100%);
}

@media screen and (min-width:768px) {
    .fpap-info-mg {
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media screen and (max-width:767px) {
    .fpap-title {
        font-size: 24px;
        line-height: 30px;
        margin: 85px 0 20px 0;
    }

    .fpap-desc {
        font-size: 16px;
        line-height: 22px;
    }

    .fpap-select {
        height: 50px;
        font-size: 15px;
        margin-bottom: 20px;
    }

    .fpap-select-arrow {
        background-position: 92.7%;
    }

    .fpap-funds-found {
        font-size: 20px;
        line-height: 26px;
    }

    .fpap-sub-title,
    .fpap-card-subttl {
        font-size: 13px;
        line-height: 18px;
    }

    .fpap-title-row {
        margin-bottom: 20px;
        padding-left: 25px;
        padding-right: 25px;
    }

    .pd-l-0 {
        padding-left: 0px;
    }

    .fpap-info {
        padding: 18px 25px 20px 25px;
    }

    .fpap-mg-top {
        margin-top: 38px;
    }

    .fpap-fund-pt {
        font-size: 32px;
        letter-spacing: -0.5px;
        line-height: 42px;
    }

    .fpap-dt-mg-top {
        margin-top: 10px;
    }

    .fpap-spect-n-mg {
        margin-bottom: -28px;
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .fpap-risk-ttl {
        padding-right: 0px;
    }

    .fpap-hkd {
        height: 25px;
        width: 56px;
        padding: 1.5px 10.5px;
        font-size: 16px;
        line-height: 22px;
    }

    .fpap-hr-3 {
        margin-top: 0px;
    }

    .fpap-rectangle-spectrum {
        height: 32px;
        width: 325px;
    }

    .fpap-smp-rectangle-spectrum {
        height: 100%;
        width: 100%;
    }

    .fpap-rectangle-spectrum-text {
        font-size: 13px;
        line-height: 18px;
        margin-top: 12px;
        text-align: center;
    }

    .fpap-spectrum-mg {
        margin-top: 40px;
    }

    .fpap-last-note-mgb {
        margin-bottom: 0px;
    }

    .fpap-hr-1 {
        margin-top: 7px;
        margin-bottom: 27px;
    }

    .fpap-hr-2 {
        border: 1px solid #E4EFF9;
        margin-left: -0.9rem;
        margin-right: -0.9rem;
    }

    .fpap-scheme-name {
        margin-top: -3px;
    }

    .fpap-record-not-found {
        font-size: 16px;
        line-height: 22px;
    }

    .fpap-dropdown-menu {
        margin-left: 0px;
    }

    .fpap-dropdown-menu li {
        padding: 14px 21.5px;
    }

    .scheme-pdt {
        padding-top: 12px;
    }

    .fpap-card-title {
        font-size: 24px;
        line-height: 30px;
    }

    .fpap-learn-btn {
        margin-top: 10px;
        font-size: 16px;
        line-height: 22px;
        padding: 9px 20px;
    }
}