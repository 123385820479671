.details-content{
    background: #FFFFFF !important;
    padding: 0px 0px 60px 0px;
}

.details-title {
  color: #464646;
  font-family: "FS Elliot Pro";
  font-size: 42px;
  letter-spacing: 0;
  line-height: 52px;
  margin: 40px 0;
}

.details-card {
    border-radius: 15px;
    background: linear-gradient(201.88deg, rgba(234, 246, 247, 0.6) 0%, rgba(228, 239, 249, 0.6) 100%);
}

.details-card-body {
    padding: 18px 25px 30px 25px;
    
}
.details-card-body-title {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
}
.status-text{
    font-family: "FS Elliot Pro";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
}
.being-processed {
    color: #FF9231;
}
.completed {
    color: #0091DA;
}
.cancelled {
    color: #E74E1C;
}
.pending {
    color: #00A29B;
}
.failed {
    color: #E74E1C;
}
.fund-details-table td {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    padding: 4px 0px 4px 25px;
}
.fund-details-table td + td {
    font-weight: normal;
    padding: 4px 25px 4px 0px;
}
.switch-details-table td {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    padding: 5px 0px 5px 25px;
}

.switch-details-table td + td {
    padding: 5px 25px 5px 0px;
}
.switch-details-card-title{
    padding: 20px 25px 23px 25px;
}
.details-label {
  color: #464646;
  font-family: "FS Elliot Pro";
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 34px;
  margin-bottom: 15px;
}
.back-btn{
    border-radius: 34px !important;
    display: block;
    text-decoration: none;
    cursor: pointer !important;
    background: linear-gradient(206.57deg, #00C4D9 0%, #0091DA 100%);
    padding: 10px 25px;
    border: none;
    outline: none !important;
    height: 60px;
    width: 100%;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
}

.back-btn:disabled {
    background: #c7c7c7;
}

.cancel-btn {
    border-radius: 34px !important;
    display: block;
    text-decoration: none;
    cursor: pointer !important;
    padding: 10px 25px;
    border: none;
    outline: none !important;
    height: 60px;
    width: 100%;
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
    background: transparent;
    border: 1.5px solid rgba(0,145,218,0.2);
}
.cancel-yes-btn,
.cancel-no-btn{
    height: 50px;
    width: 100%;
}
.back-btn:hover,
.cancel-btn:hover,
.cancel-yes-btn:hover,
.cancel-no-btn:hover {
    color: #0091DA;
    background: linear-gradient(207deg, #EAF6F7 0%, #E4EFF9 100%);
}
.modal-btn-mg{
    margin: 20px 0px 10px 0px;
}
.modal-background{
    background-color: rgba(234, 246, 247, 0.5);
}
.modal-pop {
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 5px 0 rgb(70 78 126 / 3%), 0 5px 30px 0 rgb(70 78 126 / 8%);
    border: 0 !important;
    text-align: center;
}
.detail-modal-margin{
    margin-left: 15px;
    margin-right: 15px;
}
.details-modal-body{
    padding: 30px 20px;
}
.details-modal-text{
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
}
.details-card-hr {
    margin: 0px 25px 5px 25px;
    color :rgba(70,78,126,0.15);
    border: 1px solid rgba(70,78,126,0.15);
}
.details-mg-btm {
    margin-bottom: 15px;
}

.dtls-ttl-mg {
    margin-bottom: 24px;
}

.cds-contri-nm {
    color: #0091DA;
    font-family: "FS Elliot Pro";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
}

.cds-contri-hr {
    margin-top: 8px;
    margin-bottom: 23px;
    border-top: 1px solid rgba(70,78,126,0.15);
}


@media screen and (max-width:767px){
    .details-title {
        font-size: 24px;
        line-height: 30px;
        margin: 85px 0 40px 0;
    }

    .details-card-body-title {
        font-size: 16px;
        line-height: 22px;
    }

    .fund-details-table td,
    .fund-details-table td + td{
        font-size: 13px;
        line-height: 18px;
    }

    .switch-details-table td{
        font-size: 13px;
        line-height: 18px;
        padding: 7px 0px 7px 15px;
    }
    .switch-details-table td + td{
        font-size: 15px;
        line-height: 22px;
        padding: 7px 15px 7px 0px;
    }

    .details-label {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 10px;
    }
    
    .back-btn,
    .back-btn:hover,
    .cancel-btn,
    .cancel-btn:hover {
        height: 50px;
        width: 100%;
        font-size: 16px;
        line-height: 22px;
    }
    .cancel-yes-btn,
    .cancel-yes-btn:hover,
    .cancel-no-btn,
    .cancel-no-btn:hover {
        height: 50px;
        width: 100%;
    }
    .btn-top-mg {
        margin-top: 10px;
    }
    .last-card-mg{
        margin-bottom: -10px;
    }
    .details-card-body {
        padding: 18px 15px 30px 15px;
    }
    .fund-details-table td {
        padding: 4px 0px 4px 15px;
    }

    .fund-details-table td + td {
        padding: 4px 15px 4px 0px;
    }

    .switch-details-card-title{
        padding: 15px 15px 21px 15px;
    }

    .details-card-hr {
        margin: 0px 15px 5px 15px;
    }

    .details-mg-btm {
        margin-bottom: 10px;
    }
    
    .dtls-ttl-mg {
        margin-bottom: 20px;
    }

    .cds-contri-nm {
        font-size: 13px;
        line-height: 18px;
    }

    .cds-contri-hr {
        margin-top: 3.5px;
        margin-bottom: 19.5px;
    }
}
