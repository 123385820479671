.aiib-logo {
  height: 56px;
  width: 243px;
}

.footer-aiib {
  width: 100%;
  background-color: #FBFBFB;
  padding: 48px;
  margin: 0;
}

.footer-aiib-absolute {
  width: 100%;
  background-color: #FBFBFB;
  padding: 48px;
  margin: 0;
  bottom: 0;
  position: relative;
}

.principal-logo-aiib {
  height: 23.21px;
  width: 108.31px;
  display: block;
  margin: 0 auto;
}

.footer-aiib-text {
  color: #616267;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 26px;
  display: block;
  text-align: center;
}

.axa-logo {
  height: 56px;
  width: 100px;
  margin: 0 0 0 50px;
}

@media screen and (max-width: 700px) {
  .axa-logo {
    height: 50px;
    width: 70px;
    margin: 0 0 0 30px;
  }
}

@media (min-width: 1281px) and (min-height: 929px) {
  .footer-aiib-absolute {
    position: absolute;
  }
}