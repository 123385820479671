.fund-rectangle {
    border-radius: 10px;
    background: linear-gradient(206.57deg, rgba(234, 246, 247, 0.6) 0%, rgba(228, 239, 249, 0.6) 100%);
}

.fund-rect-inner-pad {
    padding-left: 30px;
    padding-right: 33px;
    padding-top: 25px;
    padding-bottom: 16px;
}

.fund-usd-rectangle {
    box-sizing: border-box;
    width: 67px;
    height: 36px;
    border: 1px solid rgba(70, 78, 126, 0.15);
    border-radius: 20px;
    text-align: center;
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 26px;
}

.over-view-note {
    padding-top: 30px;
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 22px;
}

.nav-lbl {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 22px;
}

.nav-val {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 42px;
    letter-spacing: 0;
    line-height: 52px;
}

.fund-dc-lbl {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
}

.fund-dc-val {
    color: #B30000;
    font-family: FS Elliot Pro !important;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 34px;
}

.fund-vd-lbl {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
}

.fund-vd-val {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 34px;
}

.fund-inv-lbl {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    margin-top: 60px;
    margin-bottom: 23px;
}

.fund-inv-val {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
}

.fund-inv-note-sec {
    margin-bottom: 80px;
}

.tab-seprator {
    border-top: 2px solid rgba(228,239,249,1);
}

.field-seprator-hr {
    border-top: 1px solid rgba(225,230,244,1);
}
.fd-hr-mgb {
    margin-bottom: 80px;
}

.fd-title {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 42px;
    letter-spacing: 0;
    line-height: 52px;
    margin-bottom: 59px;
}

.fd-field-lbl {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
}

.fd-field-val {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
}

.fd-risk {
    margin-left: 9px;
}

@media (min-width: 0px) and (max-device-width: 768px) {
    .fund-rect-inner-pad {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 18px;
        padding-bottom: 20px;
    }
    .fund-usd-rectangle {
        width: 55px;
        border: 1px solid rgba(70, 78, 126, 0.15);
        border-radius: 20px;
        font-size: 16px;
    }
    .over-view-note {
        font-size: 12px;
        line-height: 18px;
    }
    .nav-lbl {
        line-height: 18px;
    }
    .nav-val {
        font-size: 32px;
        letter-spacing: -0.5;
        line-height: 42px;
    }
    .fund-dc-lbl {
        font-size: 13px;
        line-height: 18px;
    }
    .fund-dc-val {
        font-size: 18px;
        line-height: 26px;
    }
    .fund-vd-lbl {
        font-size: 13px;
        line-height: 18px;
    }
    .fund-vd-val {
        font-size: 18px;
        line-height: 26px;
    }
    .fund-inv-lbl {
        font-size: 13px;
        line-height: 18px;
        margin-top: 40px;
        margin-bottom: 0px;
    }
    .fund-inv-val {
        color: #464646;
        font-family: FS Elliot Pro !important;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 26px;
    }
    .fund-inv-note-sec {
        margin-bottom: 60px;
    }
    .fd-title {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 40px;
    }
    .fd-field-lbl {
        font-size: 13px;
        line-height: 18px;
    }
    .fd-field-val {
        font-size: 16px;
        line-height: 22px;
    }

    #fund_details_returns {
        overflow-x: scroll;
    }

    .fd-hr-mgb {
        margin-bottom: 60px;
    }
}