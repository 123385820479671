.rectangle-copy-24 {
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 5px 0 rgba(70, 78, 126, 0.03), 0 5px 30px 0 rgba(70, 78, 126, 0.08);
}

.rectangle {
    background-color: rgba(234, 246, 247, 0.5);
}

.successpopup {
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 5px 0 rgba(70, 78, 126, 0.03), 0 5px 30px 0 rgba(70, 78, 126, 0.08);
    border: 0 !important;
}

.btn-successpage {
    font-size: 16px !important;
    line-height: 22px !important;
}

@media only screen and (max-width: 600px) {
    .btn-successpage {
        font-size: 14px;
    }
    .rectangle {
        background-color: rgba(234, 246, 247, 0.5);
    }
    .successpopup {
        width: 288px;
        border-radius: 10px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 5px 0 rgba(70, 78, 126, 0.03), 0 5px 30px 0 rgba(70, 78, 126, 0.08);
        border: 0 !important;
    }
}
.mobile-error-font{
    font-weight: normal !important;
}
.disable-Mobile{
    color: #0091DA
}