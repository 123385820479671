.highcharts-axis-title {
  color: #464646 !important;
  font-family: "FS Elliot Pro-Regular", Helvetica, Arial, sans-serif !important;
  font-size: 16px !important;
  font-weight: bold !important;
}
.highcharts-credits {
  display: none;
}
.highcharts-range-selector-buttons .highcharts-label {
    transform: translate(0px,-5px);
}

input[name="min"] {
  color: #464646 !important;
  font-family: "FS Elliot Pro-Regular", Helvetica, Arial, sans-serif !important;
  font-size: 15px !important;
  top: -3.5px !important;
  left: 53px !important;
  width: auto !important;
  height: auto !important;
  padding: 10px 2px 10px 2px !important;
  background-color: #e4eff9 !important;
  border: none !important;
  border-radius: 20px;
  box-shadow: none !important;
}
input[name="min"]:focus {
  outline: none !important;
}
input[name="max"] {
  color: #464646 !important;
  font-family: "FS Elliot Pro-Regular", Helvetica, Arial, sans-serif !important;
  font-size: 15px !important;
  top: -3.5px !important;
  left:220px !important;
  width: auto !important;
  height: auto !important;
  padding: 10px 2px 10px 2px !important;
  background-color: #e4eff9 !important;
  border: none !important;
  border-radius: 20px;
  box-shadow: none !important;
}
input[name="max"]:focus {
  outline: none !important;
}
input[name="min"]::-webkit-calendar-picker-indicator {
  margin-left: 0px;
  margin-right: 10px;
}
input[name="max"]::-webkit-calendar-picker-indicator {
  margin-left: 0px;
  margin-right: 10px;
}



input[name="min"]::-webkit-datetime-edit{
    width:20px;
}

.highcharts-range-selector-buttons text {
  color: #464646 !important;
  font-size: 15px !important;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
}
.highcharts-range-selector-buttons .highcharts-button text {
  color: #464646 !important;
  font-size: 15px !important;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
}
.highcharts-range-label text {
  font-family: "FS Elliot Pro-Regular", Helvetica, Arial, sans-serif !important;
  font-size: 15px !important;
  color: #464646 !important;
  font-weight: bold;
  line-height: 22px;
}
.highcharts-xaxis-labels text {
  color: #464646 !important;
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 20px;
}
.highcharts-yaxis-labels text {
  color: #464646 !important;
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 20px;
}
.highcharts-navigator-outline {
  opacity: 0 !important;
  height: 0px !important;
}
.highcharts-navigator-mask-outside {
  fill: #e4eff9 !important;
}
.highcharts-navigator-mask-inside {
  fill: #0091da !important;
  height: 30px !important;
  transform: translate(0px, -10px);
  rx: 20px;
}
.highcharts-navigator-xaxis {
  opacity: 0 !important;
}
.highcharts-navigator-handle {
  opacity: 0 !important;
}
.highcharts-scrollbar-track {
  fill: transparent !important;
  height: 0px !important;
}
.highcharts-scrollbarhighcharts-scrollbar {
  opacity: 0 !important;
  height: 0px !important;
}
.highcharts-scrollbar-button {
  opacity: 0 !important;
}
.highcharts-scrollbar-arrow {
  opacity: 0 !important;
}
.highcharts-scrollbar-thumb {
  opacity: 0 !important;
  height: 0px !important;
}
.highcharts-scrollbar-rifles {
  opacity: 0 !important;
  height: 0px !important;
}
@media screen and (max-width:340px) {
  input[name="max"] {
    left: 185px !important;
  }
  input[name="min"]::-webkit-calendar-picker-indicator {
      margin-left: 0px !important;
      margin-right: 8px !important;
    }
    input[name="max"]::-webkit-calendar-picker-indicator {
      margin-left: 0px !important;
      margin-right: 10px !important;
    }
}
@media screen and (min-width:340px) and (max-width:767px) {
  input[name="max"] {
      left: 205px !important;
  }
}
@media screen and (max-width: 500px) {
  input[name="min"] {
      top: 51.5px !important;
  }
  input[name="max"] {
      top: 51.5px !important;
  }
}
@media screen and (min-width: 500px) and (max-width: 767px) {
  input[name="min"] {
      top: 8.5px !important;
  }
  input[name="max"] {
      top: 8.5px !important;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .highcharts-range-selector-buttons .highcharts-label {
      visibility: visible !important;
      transform: translate(-40px, -5px) !important;
  }
}
@media screen and (max-width:767px) {
  .highcharts-range-selector-buttons .highcharts-label {
      visibility: visible;
      transform: translate(-40px, -5px);
  }
  input[name="min"] {
      width: 120px !important;
      left: 49px !important;
      padding: 0px !important;
  }
  input[name="max"] {
      width: 120px !important;
      padding: 0px !important;
  }
  input[name="min"]::-webkit-calendar-picker-indicator {
      margin-left: 0px;
      margin-right: 3px;
    }
    input[name="max"]::-webkit-calendar-picker-indicator {
      margin-left: 0px;
      margin-right: 3px;
    }
}