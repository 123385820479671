.lbl-verifyField {
  color: #464646;
  font-family: FS Elliot Pro !important;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
}

.val_verifyField {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
}

.checkbox-label-verify {
    font-weight: normal;
    font-size: 18px !important;
    height: 26px !important;
    display: block;
    color: #464646 !important;
}

.checkbox-label-saveAllClientsData {
  font-weight: normal;
  font-size: 18px !important;
  height: 26px !important;
  display: block;
  color: #464646 !important;
  margin-top: 20px;
}

.place-res-verify{
  padding-top: 10px;
}

.verify-pwdToggle {
  cursor: pointer;
  float: right;
  margin: -41px 10px 0 0;
}

.verify-pswd-ip {
  display: block;
  width: 100%;
  height: 52px !important;
  border: 1px solid rgba(70, 78, 126, 0.15) !important;
  border-radius: 40px !important;
  background-color: #ffffff !important;
  color: #464646 !important;
  padding: 0 29px 0 20px !important;
}

@media (min-width: 0px) and (max-device-width: 768px) {
    .lbl-verifyField {
        color: #464646;
        font-family: "FS Elliot Pro";
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
    }
    .title-verifyInfo {
        font-size: 24px !important;
        letter-spacing: -0.5px;
        line-height: 30px !important;
        margin-top: 30px;
        margin-bottom: 24px !important;
    }
    .title-verifyInfo {
        font-size: 42px;
        line-height: 52px;
    }
    .verify-pwdToggle {
        cursor: pointer;
        float: right;
        margin: -35px 10px 0 0;
    }
}

@media (min-width: 768px) and (max-device-width: 1060px) {
  .contactVerify {
    margin-right: 120px !important;
  }
}

@media (min-width: 768px) and (max-device-width: 1060px) {
  .checkSaveForAllClients {
    margin-right: 120px !important;
  }
}

.btn-verifysubmit {
  width: 100% !important;
}

.btn-verifysubmit:disabled {
  background: #c7c7c7 !important;
  color: #ffffff;
  font-family: FS Elliot Pro !important;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
}

.pinError {
  height: 18px;
  width: 199px;
  color: #e74e1c;
  font-family: "FS Elliot Pro";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
}

.title-verifyInfo {
  font-size: 42px;
  line-height: 52px;
}

.error-msg-box {
  border-color: #e74e1c !important;
}

.err-form-control:focus {
  background-color: #fff !important;
  border-color: #e74e1c !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgb(231 78 28 / 25%) !important;
}

.enter-pin-to-confirm {
  line-height: 22px;
}
.verify-btn-input {
  height: 60px !important;
}
.verify-spinnerStyle{
  height: 40px !important;
  width: 40px!important;
  color: white !important;
}
@media (min-width: 0px) and (max-device-width: 768px) {
  .verify-btn-input {
    height: 50px !important;
  }
  .verify-checkbox {
    width: 250px !important;
  }
  .verify-spinnerStyle{
    height: 30px !important;
    width: 30px!important;
  }
}
.verify-checkbox {
  width: max-content;
}
