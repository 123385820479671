@media (min-width: 992px) {
    .color-white {
        background-color: white;
    }
    .fund_info_tab_view {
        padding-top: 25px;
    }
    #fund_info_tab_overview {
        margin-top: 60px
    }
    .fund_info_tab_view_titles {
        height: 70px;
        width: 87px;
        color: #464646;
        font-family: "FS Elliot Pro" !important;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 22px;
        margin-right: 10px;
        padding-bottom: 21px;
    }
    .fund-info-broucher {
        margin-left: 120px;
    }
    .fund_info_tab_view_actions {
    color: #0091da !important;
    font-family: "FS Elliot Pro" !important;
    font-weight: bold !important;
    font-size: 15px !important;
    letter-spacing: 0 !important;
    line-height: 22px !important;
    float: right !important;
    }
    .fund_info_tab_view_icon {
        height: 20px !important;
        width: 20px !important;
        margin-left: 10px;
    }
    .fund_info_tab_view_active_tab {
        border-bottom: 2px solid #0091da;
        color: #0091da;
    }
}

@media (max-width: 992px) {
    .color-white {
        background-color: white;
    }
    .fund_info_tab_view {
        width: 100%;
        overflow-x: auto;
        padding-top: 25px;
    }
    .fund_info_tab_view_titles {
        height: 18px;
        width: 58px;
        color: #464646;
        font-family: "FS Elliot Pro" !important;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
        margin-right: 12px;
        padding-bottom: 21px;
    }
    .fund_info_tab_view_actions {
    color: #0091da !important;
    font-family: "FS Elliot Pro" !important;
    font-weight: bold !important;
    font-size: 15px !important;
    letter-spacing: 0 !important;
    line-height: 22px !important;
    float: right !important;
    }
    .fund_info_tab_view_icon {
        height: 20px !important;
        width: 20px !important;
        margin-left: 10px;
    }
    .fund_info_tab_view_active_tab {
        border-bottom: 2px solid #0091da;
        color: #0091da;
    }
    #fund_info_tab_overview {
        margin-top: 25px
    }
}


/*Landing Sub Navbar */

.fund-info-tab-sub-nav-bg {
    color: #464646;
    top: 90px;
    position: -webkit-sticky;
    position: sticky;
    z-index: 90;
}

.fund-info-tab-padding {
    padding: inherit;
}

@media screen and (max-width: 991px) {
    .fund-info-tab-sub-nav-bg {
        color: #464646;
        top: 74px;
        position: -webkit-sticky;
        position: sticky;
        z-index: 90;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .fund-info-tab-sub-nav-bg {
        top: 55px;
    }

    .fo-mg-top {
        margin-top: 30px;
    }
}
