.active{
    color:#0091DA;
}

.breadcrumb-item {
    font-family: "FS Elliot Pro";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
}

.hover {
    cursor: pointer;
}
@media only screen and (min-width: 992px) {

    .breadcrumb{
        background-color: white;
        padding: 17px 0;
        width: 100%;
    }


  }
  .breadcrumbbackground{
   background: none;
  }