.tHeaderRow {
  opacity: 1;
  background-color: #e4eff9;
}

.wrap-data {
  width: 100;
  white-space: nowrap;
}

.fund-returns {
  height: 52px !important;
  color: #464646;
  font-size: 42px !important;
  letter-spacing: 0;
  line-height: 52px;
  margin-bottom: 59px;
}

.cumulative-return {
  height: 34px;
  color: #464646;
  font-family: "FS Elliot Pro";
  font-size: 24px !important;
  letter-spacing: 0;
  line-height: 34px;
}

.calendar-year-return {
  height: 34px;
  width: 278px;
  color: #464646;
  font-family: "FS Elliot Pro";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 34px;
  margin-top: 60px;
}

.download-print-icon {
  height: 19px;
  width: 20px;
  margin-right: 10px;
}

.fr-download-btn {
  border-radius: 30px;
  background-color: #E4EFF9;
  border: none;
  padding: 14px 20px;
  color: #0091DA;
  font-family: "FS Elliot Pro";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 22px;
  margin-right: 10px;
}

@media (max-width: 780px) {
  .fund-returns {
    height: 30px;
    width: 325px;
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 24px;
    letter-spacing: 0;
    line-height: 30px;
  }

  .cumulative-return {
    height: 26px;
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 18px !important;
    letter-spacing: 0;
    line-height: 26px;
  }

  .calendar-year-return {
    height: 26px;
    width: 208px;
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
  }
}