.ch-datePicker {
    height: 60px !important;
    border-radius: 30px !important;
    display: block !important;
    width: 100% !important;
    border: none !important;
    background-color: #E4EFF9 !important;
}

.ch-fullwidht {
    width: 100%;
}

.ch-select {
    height: 50px !important;
    margin-bottom: 20px !important;
    box-sizing: border-box !important;
    border: 1px solid rgba(70, 78, 126, 0.15) !important;
    border-radius: 35px !important;
    background-color: #FFFFFF !important;
    color: #464646 !important;
    font-family: FS Elliot Pro !important;
    font-size: 15px !important;
    font-weight: bold !important;
    letter-spacing: 0 !important;
    line-height: 22px !important;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.ch-submitbtn-text {
    color: #FFFFFF !important;
    font-family: FS Elliot Pro !important;
    font-size: 16px !important;
    font-weight: bold !important;
    letter-spacing: 0 !important;
    line-height: 22px !important;
    text-align: center !important;
    display: inline-block !important;
    margin-bottom: 40px !important;
}

.ch-submitbtn {
    height: 50px !important;
    cursor: pointer !important;
    border-radius: 34px;
    float: right !important;
    background: linear-gradient(206.57deg, #00C4D9 0%, #0091DA 100%);
}

.lbl-ch-statusType {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    margin-bottom: 8px !important;
    margin-top: 0px !important;
}

.lbl-ch-title {
    margin-top: 34px !important;
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 30px !important;
}

.ch-btn-submit-div {
    padding-top: 35px;
    text-align: right;
}

.lbl-ch-date {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    margin-bottom: 8px !important;
}

.ch-cardBody {
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 5px 0 rgba(70, 78, 126, 0.03), 0 5px 30px 0 rgba(70, 78, 126, 0.1);
    padding: 15px 15px 10px 15px !important;
}

.ch-paginationMarginicon {
    padding-top: 13px !important;
}

.ch-paginationMargin {
    padding-top: 9px !important;
}

.ch-leftarrow {
    transform: scaleX(-1);
}

.ch-leftarrordiv {
    opacity: 0.3;
    border-radius: 52px;
    background-color: #E4EFF9 !important;
}

.ch-rightarrordiv {
    border-radius: 52px;
    background-color: #E4EFF9 !important;
}

.ch-page-item {
    width: 50px;
    height: 50px;
    font-family: FS Elliot Pro !important;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center !important;
}

.ch-activePage {
    color: #0091DA;
    font-family: FS Elliot Pro !important;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    cursor: pointer !important;
}

.ch-activePage:hover {
    color: #0091DA;
    font-family: FS Elliot Pro !important;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    cursor: pointer !important;
}

.ch-deactivePage {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    cursor: pointer !important;
}

.ch-deactivePage {
    color: #464646 !important;
    font-family: FS Elliot Pro !important;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    cursor: pointer !important;
}

.lbl-ch-card-fundName {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
}

.lbl-ch-fund-status {
    font-family: FS Elliot Pro !important;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    margin-bottom: 5px !important;
}

.value-lbl-ch-card-field {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
}

.ch-pagination-padding {
    padding: 0px !important;
}

.ch-cursor-pointer {
    cursor: pointer !important;
}

.ch-note-lbl {
    color: #464646;
    font-family: FS Elliot Pro !important;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    margin-bottom: 15px !important;
}

.ch-note-text {
    color: #767676;
    font-family: FS Elliot Pro !important;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
}

.ch-note-number {
    color: #0091DA !important;
}

.ch-note-div {
    margin-bottom: 60px !important;
}

.ch-line {
    box-sizing: border-box !important;
    height: 1px !important;
    border: 1px solid rgba(70, 78, 126, 0.15) !important;
}

.ch-date-input {
    color: #464646 !important;
    font-family: FS Elliot Pro !important;
    font-size: 13px !important;
    letter-spacing: 0 !important;
    line-height: 18px !important;
    height: 48px !important;
    border-radius: 40px !important;
    background-color: #E4EFF9 !important;
    background-image: url(../assets/Icon/Icon_calendar.svg) !important;
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border: none !important;
    padding-left: 15.5px !important;
}

@media screen and (min-width: 768px) {
    .ch-select {
        height: 50px !important;
    }
    .ch-submitbtn {
        height: 50px !important;
    }
}

@media (min-width:0px) and (max-width:768px) {
    .lbl-ch-title {
        margin-top: 90px !important;
        margin-bottom: 30px !important;
    }
    .ch-btn-submit-div {
        padding-top: 0px !important;
    }
    .ch-date-input {
        height: 30px !important;
        border-radius: 15px !important;
    }
}