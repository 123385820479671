.spinner-border-dashboard {
    position: absolute;
    z-index: 1;
    left: 50%;
    right: 50%;
    top: 50%;
    bottom: 50%;
  }

  .full-screen-dashboard {
      height: 100vh;
  }