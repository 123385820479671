.bubble{
    box-sizing: border-box;
    height: 23px;
    width: 56px;
    border: 1px solid rgba(70,78,126,0.15);
    border-radius: 15px;
    display: inline-flex;
    align-items:center;
    justify-content: center;
    margin-left:5px;
}