@media (min-width: 768px) {
  .ei-modal .ei-modal-dialog {
    width: 50% !important;
  }
}
.email-modal-body {
  padding: 30px 20px;
  color: #464646;
  font-family: "FS Elliot Pro";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
}

.email-icon {
  margin-right: 8px !important;
}

.email-popup {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgb(70 78 126 / 3%), 0 5px 30px 0 rgb(70 78 126 / 8%);
  border: 0 !important;
  text-align: center;
}

.invalid-Email_lbl {
  color: #0091da;
  font-family: FS Elliot Pro !important;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
}

.disable-Email {
  opacity: 0.4;
}

.mp-ea-note {
  overflow-wrap: break-word;
  white-space: initial;
}

.mp-ea-note {
    overflow-wrap: break-word;
    white-space: initial;
}

@media (min-width: 0px) and (max-device-width: 768px) {
  .invalid-Email_lbl {
    color: #0091da;
    font-family: FS Elliot Pro !important;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
  }
}
