.promotional-banner{
    height: 600px;
    width: 100%;
    background-image: url('../assets/Promotional_Banner_mobile.jpg');
    background-size: cover;
}


@media (min-width: 768px) {
    .promotional-banner{
        height: 600px;
        width: 100%;
        background-image: url('../assets/promotional-banner.jpg');
        background-size: cover;
   }
}