.lss-modal-background {
    background-color: rgba(51, 51, 51, 0.9)
}

.lss-modal-content {
    border-radius: 15px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 5px 0 rgba(70, 78, 126, 0.03), 0 5px 30px 0 rgba(70, 78, 126, 0.1);
}

.lss-modal-body {
    padding: 48px 32px;
    color: #464646;
    font-family: "FS Elliot Pro";
}

@media (min-width: 576px) {
    .lss-modal-dlg {
        max-width: 720px;
    }
}

.lss-btn {
    border-radius: 34px;
    border: none;
    background: linear-gradient(206.57deg, #00C4D9 0%, #0091DA 100%);
    color: #FFFFFF;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
    padding: 17px 0;
    width: 100%;
}

.lss-btn:hover {
    color: #0091DA;
    background: linear-gradient(207deg, #EAF6F7 0%, #E4EFF9 100%);
}

.lss-table thead td {
    background-color: #f1f7fc;
    border-bottom: 2px solid rgba(228, 239, 249, 1);
}

.lss-table thead {
    background-color: rgba(228, 239, 249, 0.5);
}

.lss-table td,
.lss-table th {
    padding: 12px 16px;
}

.lss-table td {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    border-top: none;
}

.lss-table tbody td {
    background-color: #FFFFFF;
    border-bottom: 1px solid rgba(216, 216, 216, 1);
}

.lss-modal-title {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 34px;
}

.lss-modal-subttl {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 16px;
    margin-top: 24px;
}

.lss-list {
    margin-left: 15px;
}

.lss-mgb {
    margin-bottom: 48px;
}

.lss-mgt {
    margin-top: 24px;
}

.lss-para {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
}

@media screen and (max-width:767px) {
    .lss-modal-body {
        padding: 24px 16px;
    }

    .lss-btn-mg {
        padding-left: 16px;
        padding-right: 16px;
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.lss-scroller::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.lss-scroller {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}