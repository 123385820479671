.fatca-modal-background {
    background-color: rgba(51,51,51,0.9);
}

@media (min-width: 576px) {
    .fatca-modal-dlg {
        max-width: 720px
    }
}

.fatca-modal-content {
    border-radius: 15px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 5px 0 rgba(70,78,126,0.03), 0 5px 30px 0 rgba(70,78,126,0.1);
}

.fatca-modal-body {
    padding: 56px 32px 48px;
    color: #464646;
    font-family: "FS Elliot Pro";
}

.fatca-modal-title {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 34px;
    margin-bottom: 12px;
}

.radio-option>input:checked+label:after {
    background-color: #0091DA !important;
}

.radio-option>input+label::before {
    border: 1px solid #C7C7C7;
}

.radio-option>input+label {
    font-size: 18px;
}

.fatca-option {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
}

.fatca-option-subttl {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
}

.fatca-card {
    border-radius: 15px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 5px 0 rgba(70,78,126,0.03), 0 5px 30px 0 rgba(70,78,126,0.1);
    max-height: 400px;
    margin-top: 48px;
    margin-bottom: 48px;
}

.fatca-card-content{
    padding: 16px 24px;
}

.fatca-a-btn {
    border-radius: 34px;
    border: none;
    background: #0061A0;
    color: #FFFFFF;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
    width: 100%;
    height: 60px;
}

.fatca-a-btn:hover,
.fatca-d-btn:hover {
    color: #0091DA;
    background: linear-gradient(207deg, #EAF6F7 0%, #E4EFF9 100%);
}

.fatca-a-btn:disabled{
    background: #C7C7C7;
}

.fatca-d-btn {
    border-radius: 34px;
    border: 1px solid #0061A0;
    background: #FFFFFF;
    color: #0061A0;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
    width: 100%;
    height: 60px;
}

.fatca-d-btn:hover {
    color: #0091DA;
    background: linear-gradient(207deg, #EAF6F7 0%, #E4EFF9 100%);
}

.fatca-tin-input::placeholder {
    color: rgba(70,70,70,0.5) !important;
}


@media screen and (max-width:767px){
    .fatca-modal-body {
        padding: 32px 16px;
    }
    .fatca-d-btn {
        margin-bottom: 10px;
    }

    .fatca-a-btn,
    .fatca-d-btn {
        height: 50px;
    }
}