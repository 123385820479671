.sa-title {
    font-size: 42px;
    letter-spacing: 0;
    line-height: 52px;
    margin: 5px 0 0;
}

.sa-login {
    color: #464646;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
}

.sa-heading {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 34px;
    margin: 60px 0 0;
}

.sa-subHeading {
    color: #464646;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
    margin: 5px 0 0;
}

.sa-card {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: none !important;
    border-radius: 15px;
    background-color: #FFFFFF;
    box-shadow: 0 20px 40px 2px rgba(70, 78, 126, 0.16);
    padding: 20px 25px 19px;
    margin: 60px 0 0;
    cursor: pointer;
    height: 230px;
}

.sa-memNmbr {
    color: #464646;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    margin: 0 0 0;
}

.sa-memNmbr-value {
    color: #464646;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    margin: 0 0 0;
}

.sa-ammount {
    font-size: 42px;
    letter-spacing: 0;
    line-height: 52px;
    margin: 19px 0 0;
    display: flex;
}

.sa-hkd {
    box-sizing: border-box;
    border: 1px solid rgba(70, 78, 126, 0.15);
    border-radius: 15px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    width: fit-content;
    height: fit-content;
    padding: 4.5px 10.5px;
    margin: 10px 0 0 15px;
}

.sa-company {
    color: #464646;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
    margin: 20px 0 0;
    text-transform: capitalize;
}

.sa-portfolio {
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    margin: 30px 0 48px;
}

.sa-arrow {
    height: 14px;
    width: 17px;
    margin: 0 1.5px 0 0;
    float: right;
}

.sa-note-title {
    display: inline-block;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    margin-top: 0px;
    width: 100%;
}

.sa-note-line {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
    margin: 12px 0 0;
    color: #767676;
    width: inherit;
}

@media (min-width: 216px) and (max-device-width: 758px) {
    .sa-title {
        font-size: 24px;
        line-height: 30px;
        margin: 30px 0 0;
        overflow-wrap: break-word !important;
    }
    .sa-login {
        font-size: 13px;
        line-height: 18px;
        float: left;
    }
    .sa-membrNmbr {
        margin: 5px 0 0;
    }
    .float-details {
        float: left !important;
    }
    .sa-heading {
        font-size: 18px;
        line-height: 26px;
        margin: 30px 0 0;
    }
    .sa-subHeading {
        font-size: 15px;
        line-height: 22px;
        margin: 3px 0 0;
    }
    .sa-card {
        border-radius: 10px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 5px 0 rgba(70, 78, 126, 0.03), 0 5px 30px 0 rgba(70, 78, 126, 0.1);
        ;
        padding: 15px;
        margin: 30px 0 0;
        height: auto;
    }
    .sa-ammount {
        font-size: 32px;
        letter-spacing: -0.5px;
        line-height: 42px;
        margin: 15px 0 0;
    }
    .sa-hkd {
        padding: 4px 10px;
        margin: 5px 0 0 5px;
    }
    .sa-company {
        font-size: 15px;
        line-height: 22px;
        margin: 15px 0 0;
    }
    .sa-portfolio {
        font-size: 13px;
        line-height: 18px;
        margin: 20px 0 25px;
    }
}

.sa-membrNmbr {
    margin: 15px 0 0;
}

.float-details {
    float: right;
}

@media (min-device-width: 768px) {
    .sa-welcome-block-margn {
        margin-top: 45px;
    }
}