.db-fundsList-item {
  height: 26px;
  width: 600px;
  color: #464646;
  font-family: "FS Elliot Pro" !important;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
}

.db-fundsList-block {
  margin-top: 16px;
  margin-bottom: 16px;
}

.db-fundsList-title {
  display: inline-block;
  height: 26px;
  width: 290px;
  color: #464646;
  font-family: "FS Elliot Pro" !important;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
}

.db-fundsList-comment {
  height: 26px;
  width: 415px;
  color: #464646;
  font-family: "FS Elliot Pro" !important;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 22px;
}

.db-fundList-colName {
  display: inline-block;
  height: 22px;
  color: #464646;
  font-family: "FS Elliot Pro" !important;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: 35px;
  margin-bottom: 16px;
}

.db-fundList-item-fund-holdingPc{
    height: 22px;
    width: 31px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
}

.db-fundList-item-fund-name{
    height: 22px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    margin: -3px 0 0 10px;
}

.db-fundsList-amount {
    height: 22px;
    width: 105px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
  }

.db-fundsList-colNames-block {
  margin-top: 27px;
}

.db-fundList-colName-fundName {
  float: left;
}

.db-fundsList-colNames-block .db-fundList-colName-fundName,.db-fundList-colName-value{
    height: 18px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
}

.db-fundsList-colItems-block .db-fundList-colName-fundName,.db-fundList-colName-value,.db-fundList-colName-holdingPc{
    height: 22px;
  color: #464646;
  font-family: "FS Elliot Pro" !important;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 22px;
}


.db-fundList-colName-buck{
    font-size: 15px;
    line-height: 22px;
    vertical-align: middle;
    float:right;
}

.db-fundList-colName-value {
    padding-left: 10px;
    vertical-align: middle;
  float: right;
}

.db-fundList-colName-holdingPc {
  float: left;

}

.db-fundList-riskspectrum-graphic{
  margin-bottom:36px;
}

.db-fundList-riskspectrum-title {
  height: 22px;
  width: 163px;
  color: #464646;
  font-family: "FS Elliot Pro" !important;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
}

.db-fundList-riskspectrum {
  display: inline-block;
  margin-top: 30px;
}

.db-fundList-sort-icon {
  margin-left: 2px;
}

.db-fund-list-collapsed-card,.db-fund-list-expanded-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 17px;
  padding-bottom: 17px;
}

.db-fund-list-collapsed-card-title {
  height: 26px;
  width: 149px;
  color: #464646;
  font-family: "FS Elliot Pro" !important;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
}

.db-fund-list-collapsed-card-icon {
  height: 17px;
  width: 14px;
}
 
    .noMoneyImageSmall {
        height: 44px;
        margin-left: 10px;
        margin-top: 20px;
    }
    
    .noAssetfundList {
        height: 22px;
        color: #C7C7C7;
        font-family: "FS Elliot Pro";
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
        margin-left: 10px;
        margin-top: 30px;
    }

@media (min-width: 768px) {
  .db-fundList-item-fund-name{
    height: 22px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    margin: 0 0 0 10px;
}
  .db-fundsList-item {
    height: 26px;
    width: 600px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
  }

  .db-fundsList-amount {
    height: 26px;
    width: 150px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
  }

  .db-fundsList-block {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .db-fundsList-title {
    display: inline-block;
    height: 26px;
    width: 290px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
  }

  .db-fundList-item-fund-holdingPc{
    height: 22px;
    width: 31px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
  }

  .db-fundsList-comment {
    height: 26px;
    width: 415px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
  }

  .db-fundList-colName {
    display: inline-block;
    height: 22px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    margin-top: 5px;
    margin-bottom: 20px;
  }

  .db-fundList-riskspectrum-title {
    height: 22px;
    width: 163px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
  }

  .db-fundList-riskspectrum {
    display: inline-block;
    margin-top: 30px;
    margin-bottom:5px;
  }

  .db-fundList-riskspectrum-graphic{
    margin-bottom:36px;
  }

  .db-fundList-sort-icon {
    margin-left: 2px;
  }

  .db-fund-list-collapsed-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 17px;
    padding-bottom: 17px;
  }

  .db-fund-list-expanded-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 17px;
    padding-bottom: 17px;
  }

  .db-fund-list-collapsed-card-title {
    height: 26px;
    width: 149px;
    color: #464646;
    font-family: "FS Elliot Pro" !important;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
  }

  .db-fund-list-collapsed-card-icon {
    height: 17px;
    width: 14px;
  }
}